// HowItWorks.js
import React from 'react';
import './HowItWorks.css'; // Make sure to create a corresponding CSS file

function HowItWorks() {
  return (
    <section className="how-it-works">
      <h2>How It Works</h2>
      <p>Step 1: Sign up</p>
      <p>Step 2: Create your tutor</p>
      <p>Step 3: Start talking with your tutor</p>
      {/* Add more steps or details here */}
    </section>
  );
}

export default HowItWorks;
