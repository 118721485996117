// Testimonials.js
import React from 'react';
import './Testimonials.css'; // Make sure to create a corresponding CSS file

function Testimonials() {
  return (
    <section className="testimonials">
      <h2>Testimonials</h2>
      <blockquote>
        "Arc Classroom boosted my child's confidence."
        <span>- Charlotte</span>
      </blockquote>
      {/* Add more testimonials here */}
    </section>
  );
}

export default Testimonials;
