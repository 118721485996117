import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import './PasswordResetForm.css';

function PasswordResetForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setError('Please enter your email');
      return;
    }
    try {
      await axios.post('https://arcclassroom.com/api/request-password-reset', { email });
      setMessage('Password reset link sent. Please check your email.');
      setError('');
      // Optionally navigate the user to another page after a delay
      setTimeout(() => navigate('/login'), 5000);
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'Password reset failed');
      } else {
        setError('Server error. Please try again later.');
      }
    }
  };

  return (
    <section className="password-reset-form">
      <h2>Reset Password</h2>
      {error && <p className="error">{error}</p>}
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
        <button type="submit">Send Reset Link</button>
      </form>
    </section>
  );
}

export default PasswordResetForm;
