// AffiliateMarketing.js
import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure axios is installed
import './AffiliateMarketing.css'; // CSS file for styling

function AffiliateMarketing() {
  const [referralLink, setReferralLink] = useState('');
  const [earnings, setEarnings] = useState(0);
  const [referralCount, setReferralCount] = useState(0);

  // Function to fetch referral link and stats
  const fetchReferralData = async () => {
    try {
      // Replace with your actual API endpoint
      const response = await axios.get('/api/get-referral-data');
      const data = response.data;

      setReferralLink(data.referralLink);
      setEarnings(data.earnings);
      setReferralCount(data.referralCount);
    } catch (error) {
      console.error('Error fetching referral data:', error);
      // Handle errors here
    }
  };

  // Function to generate a new referral link
  const generateReferralLink = async () => {
    try {
      // Replace with your actual API endpoint
      const response = await axios.post('/api/generate-referral-link');
      setReferralLink(response.data.referralLink);
      // Optionally, refresh referral stats after generating new link
      fetchReferralData();
    } catch (error) {
      console.error('Error generating referral link:', error);
      // Handle errors here
    }
  };

  // Fetch referral data on component mount
  useEffect(() => {
    fetchReferralData();
  }, []);

  return (
    <div className="affiliate-marketing">
      <h2>Affiliate Marketing</h2>
      <button onClick={generateReferralLink}>Generate Referral Link</button>
      <p>Your Referral Link: {referralLink}</p>
      <div className="referral-stats">
        <p>Total Referrals: {referralCount}</p>
        <p>Earnings from Referrals: ${earnings}</p>
      </div>
    </div>
  );
}

export default AffiliateMarketing;
