import React from 'react';
import './BenefitsSection.css';

function BenefitsSection() {
  return (
    <section className="benefits-section">
      <h2>Empowering Learning Journeys</h2>
      <ul>
        <li>
          <strong>Homework Helpers:</strong> 
          <p>Struggle-free homework sessions, easing nightly routines.</p>
        </li>
        <li>
          <strong>Parental Peace of Mind:</strong> 
          <p>Confidence in your child’s education, even when the material is unfamiliar.</p>
        </li>
        <li>
          <strong>Empathetic Support:</strong> 
          <p>Understanding and patient assistance for every learning challenge.</p>
        </li>
      </ul>
    </section>
  );
}

export default BenefitsSection;

