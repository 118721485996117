// LoginComponent.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Import Link from 'react-router-dom'
import './LoginComponent.css';

function LoginComponent() {
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://arcclassroom.com/api/login', loginData);
      localStorage.setItem('accessToken', response.data.access_token); // Store the access token
      navigate('/setup'); // Redirect to the setup assistant page
    } catch (error) {
      setError('Login failed. Invalid email or password.');
    }
  };

  return (
    <section className="login-form">
      <h2>Login</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input type="email" name="email" placeholder="Email" value={loginData.email} onChange={handleInputChange} />
        <input type="password" name="password" placeholder="Password" value={loginData.password} onChange={handleInputChange} />
        <button type="submit">Login</button>
      </form>
      <div className="password-reset-link">
        <Link to="/password-reset">Forgot Password?</Link> {/* Add this line for the password reset link */}
      </div>
    </section>
  );
}

export default LoginComponent;
