// MainApp.js
import React, { useState, useEffect, useRef } from 'react';
import SetupAssistant from './components/SetupAssistant';
// import { Link } from 'react-router-dom';
import './MainApp.css';
import axios from 'axios';

function MainApp() {
  const [recording, setRecording] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [llmResponse, setLlmResponse] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [interpretation, setInterpretation] = useState("");
  const [setupComplete, setSetupComplete] = useState(false);
  const [assistantId, setAssistantId] = useState('');
  const [threadId, setThreadId] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('alloy'); // Default voice
  const VOICES = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];
  const [avatarUrl, setAvatarUrl] = useState('');
  const [error, setError] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);


  useEffect(() => {
    const savedAssistantId = localStorage.getItem('assistantId');
    const savedThreadId = localStorage.getItem('threadId');
    const savedAvatarUrl = localStorage.getItem('avatarUrl');
    console.log("Avatar URL:", savedAvatarUrl); // Add this line

    if (savedAssistantId && savedThreadId) {
      // IDs exist, set them and mark setup as complete
      setAssistantId(savedAssistantId);
      setThreadId(savedThreadId);
      setSetupComplete(true);
    } else {
      // IDs don't exist, indicating the setup is not complete
      setSetupComplete(false);
    }

    if (savedAvatarUrl) {
      // Avatar URL exists, set it
      setAvatarUrl(savedAvatarUrl);
    }
  }, []);

  const renderVoiceSelection = () => (
    <select value={selectedVoice} onChange={(e) => setSelectedVoice(e.target.value)}>
      {VOICES.map(voice => (
        <option key={voice} value={voice}>{voice.charAt(0).toUpperCase() + voice.slice(1)}</option>
      ))}
    </select>
  );

  const startRecording = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const options = { mimeType: 'audio/webm' };
        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                audioChunksRef.current.push(event.data);
            }
        };

        mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: options.mimeType });
            sendAudioToBackend(audioBlob); // This line calls the function
        };

        setRecording(true);
        setError(""); // Reset any previous errors
    } catch (error) {
        console.error('Error accessing the microphone', error);
        setError("Error accessing the microphone. Please check your device settings.");
    }
};

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const sendAudioToBackend = (blob) => {
    const formData = new FormData();
    formData.append('file', blob, 'recording.webm');
    setIsUploading(true);

    axios.post('https://arcclassroom.com/api/transcribe', formData)
      .then(response => {
        handleTranscription(response.data.transcription);
      })
      .catch(error => {
        console.error('Error uploading audio:', error);
        setError("Failed to upload audio. Please try again.");
      })
      .finally(() => setIsUploading(false));
  };

  const sendTextToChatModel = (text) => {
    const token = localStorage.getItem('accessToken'); // Retrieve the stored JWT token

    axios.post('https://arcclassroom.com/api/chat', { text }, {
        headers: {
            Authorization: `Bearer ${token}` // Include the token in the request header
        }
    })
    .then(response => {
        // Directly use the text response from the assistant
        const assistantText = response.data.response;
        setLlmResponse(assistantText);
        playAudioResponse(assistantText);
    })
    .catch(error => {
        console.error('Error in LLM chat:', error);
        setError("Error in language model chat. Please try again.");
    });
  };

  const playAudioResponse = (text) => {
      axios.post('https://arcclassroom.com/api/tts', { text, voice: selectedVoice }, { responseType: 'blob' })
      .then(response => {
        const audioUrl = URL.createObjectURL(response.data);
        const audio = new Audio(audioUrl);
        audio.play().catch(e => {
          console.error('Error playing audio:', e);
          setError("Error playing the audio response.");
        });
      })
      .catch(error => {
        console.error('Error in TTS:', error);
        setError("Error in text-to-speech conversion. Please try again.");
      });
  };

  const handleTranscription = (transcribedText) => {
    setTranscription(transcribedText);
    sendTextToChatModel(transcribedText);
  };

  const handleImageUpload = event => {
    setSelectedImage(event.target.files[0]);
  };

  const uploadImage = () => {
    if (selectedImage) {
      setIsUploading(true);

      const formData = new FormData();
      formData.append('image', selectedImage);
      formData.append('threadId', threadId); // pass threadId to backend

      const token = localStorage.getItem('accessToken'); // Retrieve the stored JWT token

      axios.post('https://arcclassroom.com/api/upload_image', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data' // This might be needed for file upload
        }
      })
      .then(response => {
        const interpretationText = response.data.interpretation.choices[0].message.content;
        setInterpretation(interpretationText);
        setIsUploading(false);
      })
      .catch(error => {
        console.error('Error uploading image:', error);
        setError("Failed to upload image. Please try again.");
        setIsUploading(false);
      });
    }
  };

  const handleSetupComplete = ({ assistantId, threadId }) => {
    setAssistantId(assistantId);
    setThreadId(threadId);
    setSetupComplete(true);
  };

  if (!setupComplete) {
    return <SetupAssistant onSetupComplete={handleSetupComplete} />;
  }

  // Main render
  return (
    <div className="App">
      {/* Navigation Links */}
      <nav>
        {/*<Link to="/teacher-dashboard">Teacher Dashboard</Link>*/}
        {/* Add other navigation links as needed */}
      </nav>

      {avatarUrl && <img src={avatarUrl} alt="User Avatar" className="avatar-image" />}

      {error && <div className="error-message"><p>{error}</p></div>}

      {/* Voice Recording Section */}
      <div className="voice-recording-section">
        <h3>Talk to Your Assistant</h3>
        <p>Press 'Start Recording' to ask your assistant a question, then 'Stop Recording' to get a response.</p>
        <button onClick={recording ? stopRecording : startRecording} disabled={isUploading}>
          {recording ? 'Stop Recording' : 'Start Recording'}
        </button>
        {isUploading && <p>Uploading...</p>}
        {transcription && <p className="transcription">{transcription}</p>}
        {llmResponse && <p className="llm-response">{llmResponse}</p>}
      </div>

      {/* Image Upload Section */}
      <div className="image-upload-section">
        <h3>Upload Homework Image</h3>
        <p>Upload a one-page picture from your homework for me to help review.</p>
        <input type="file" onChange={handleImageUpload} accept="image/*" />
        <button onClick={uploadImage} disabled={!selectedImage || isUploading}>
          {isUploading ? (
            <>
              <span className="spinner"></span> Uploading...
            </>
          ) : (
            'Upload Image'
          )}
        </button>
        {interpretation && <p className="interpretation">{interpretation}</p>}
      </div>

      {/* Voice Selection */}
      <div className="voice-selection-section">
        <label htmlFor="voice-selection">Choose a Voice: </label>
        {renderVoiceSelection()}
      </div>
    </div>
  );
}

export default MainApp;
