// ActualPasswordResetComponent.js

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function ActualPasswordResetComponent() {
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { token } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`https://arcclassroom.com/api/reset-password/${token}`, { password: newPassword });
      navigate('/login'); // Redirect to login after successful password reset
    } catch (error) {
      setError('Failed to reset password. Please try again.');
    }
  };

  return (
    <div>
      <h2>Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password"
        />
        <button type="submit">Reset Password</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
}

export default ActualPasswordResetComponent;
