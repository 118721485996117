import React, { useState } from 'react';
import axios from 'axios';
import './SetupAssistant.css';
import { useNavigate } from 'react-router-dom';

function SetupAssistant() {
  const [loading, setLoading] = useState(false);
  const [gradeLevel, setGradeLevel] = useState('kindergarten'); // default value
  const [personality, setPersonality] = useState('innovator'); // default personality
  const [avatarUrl, setAvatarUrl] = useState(''); // New state for avatar URL
  const navigate = useNavigate();

  const generateAvatar = async (personality) => {
    try {
      const response = await axios.post('https://arcclassroom.com/api/generate_avatar', { personality });
      if(response.data.avatar_url) {
        setAvatarUrl(response.data.avatar_url);
        localStorage.setItem('avatarUrl', response.data.avatar_url);
        console.log("Avatar URL:", response.data.avatar_url); // Check the URL
      }
    } catch (error) {
      console.error('Error generating avatar:', error);
    }
  };

  const updateUserIdentifiers = async (assistantId, threadId) => {
    try {
      await axios.post('https://arcclassroom.com/api/update_user_ids', {
        assistantId,
        threadId
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      console.log("User profile updated with new IDs");
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  const createAssistantAndThread = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the stored JWT token

      // Updated POST request to include Authorization header
      const assistantResponse = await axios.post(
        'https://arcclassroom.com/api/create_assistant',
        { gradeLevel, personality },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // Updated GET request to include Authorization header
      const threadResponse = await axios.get(
        'https://arcclassroom.com/api/create_thread',
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // Extracting assistantId and threadId from the response
      const assistantId = assistantResponse.data.assistant_id;
      const threadId = threadResponse.data.thread_id;

      // Store the IDs locally for immediate use
      localStorage.setItem('assistantId', assistantId);
      localStorage.setItem('threadId', threadId);

      // Update the user's profile in the backend with the new IDs
      await updateUserIdentifiers(assistantId, threadId);

      // Proceed with the rest of the setup
      await generateAvatar(personality);
      navigate('/main');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Welcome to your Assistant Setup</h1>
      <label htmlFor="grade-level-select">Step 1: Choose your grade level: </label>
      <select id="grade-level-select" value={gradeLevel} onChange={e => setGradeLevel(e.target.value)}>
        <option value="kindergarten">Kindergarten</option>
        <option value="elementary">Elementary</option>
        <option value="middle">Middle School</option>
        <option value="high">High School</option>
      </select>
      <label htmlFor="personality-select">Step 2: Choose a Personality for your Assistant: </label>
      <select id="personality-select" value={personality} onChange={e => setPersonality(e.target.value)}>
        <option value="innovator">Innovator (Nikola Tesla)</option>
        <option value="leader">Leader (George Washington)</option>
        <option value="artist">Artist (Taylor Swift)</option>
        <option value="explorer">Explorer (Amelia Earhart)</option>
        <option value="scientist">Scientist (Albert Einstein)</option>
        <option value="philosopher">Philosopher (Confucius)</option>
        <option value="warrior">Warrior (Sun Tzu)</option>
      </select>
      <p>Step 3: Click the button below to setup your assistant.</p>
      <button onClick={createAssistantAndThread} disabled={loading}>
        {loading ? (
          <>
            <span className="spinner"></span> Creating...
          </>
        ) : (
          'Setup Assistant'
        )}
      </button>
      {avatarUrl && <img src={avatarUrl} alt="User Avatar" style={{ width: '100px', height: '100px' }} />}
    </div>
  );
}

export default SetupAssistant;
