// ProfileSetup.js
import React, { useState } from 'react';
import axios from 'axios';
import './ProfileSetup.css';

function ProfileSetup() {
  const [profile, setProfile] = useState({ name: '', bio: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Simple validation
    if (!profile.name || !profile.bio) {
      setError('Please fill in all fields');
      return;
    }

    try {
      // Assuming you store the user's token in localStorage
      const token = localStorage.getItem('accessToken');
      await axios.post('http://localhost:5000/update-profile', profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setSuccess('Profile updated successfully');
      setError('');
      // Additional actions upon success (e.g., redirect or update UI)
    } catch (error) {
      setError('Failed to update profile');
      setSuccess('');
      // Additional error handling
    }
  };

  return (
    <div className="profile-setup">
      <h2>Profile Setup</h2>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          placeholder="Name" 
          value={profile.name} 
          onChange={(e) => setProfile({ ...profile, name: e.target.value })}
        />
        <textarea 
          placeholder="Bio" 
          value={profile.bio} 
          onChange={(e) => setProfile({ ...profile, bio: e.target.value })}
        />
        <button type="submit">Save Profile</button>
      </form>
    </div>
  );
}

export default ProfileSetup;
