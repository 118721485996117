// LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Import the CSS file
import BenefitsSection from './BenefitsSection';
import HowItWorks from './HowItWorks';
import Testimonials from './Testimonials';
import SignUpForm from './SignUpForm';
import Footer from './Footer';

function LandingPage() {
  return (
    <div className="container">
      <header className="landing-header">
        <h1>Welcome to Arc Classroom</h1>
        <p>Empowering students to become lifelong learners.</p>
        {/*<Link to="/signup" className="cta-button">Join as a Teacher</Link>*/}
        {/* Consider adding an engaging image/graphic here */}
        <div className="login-cta">
        <Link to="/login" className="login-button">Returning Users Login</Link> {/* Add a login button/link */}
        </div>

      </header>

      <BenefitsSection />
      <HowItWorks />
      <Testimonials />
      <SignUpForm />
      <Footer />
    </div>
  );
}

export default LandingPage;
