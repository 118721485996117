// TeacherDashboard.js
import React from 'react';
import ProfileSetup from './ProfileSetup';
import CourseManagement from './CourseManagement';
import PaymentIntegration from './PaymentIntegration';
import AnalyticsDashboard from './AnalyticsDashboard';
import SupportSection from './SupportSection';
import AffiliateMarketing from './AffiliateMarketing';
import './TeacherDashboard.css'; // Ensure to create a corresponding CSS file

function TeacherDashboard() {
  return (
    <div className="teacher-dashboard">
      <ProfileSetup />
      <CourseManagement />
      <PaymentIntegration />
      <AnalyticsDashboard />
      <SupportSection />
      <AffiliateMarketing />
    </div>
  );
}

export default TeacherDashboard;
