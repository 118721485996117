import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './EmailConfirmationComponent.css';

function EmailConfirmationComponent() {
    const [message, setMessage] = useState('Confirming your email...');
    const { token } = useParams();
    const navigate = useNavigate();

    const confirmEmail = useCallback(async () => {
        try {
            const response = await axios.get(`https://arcclassroom.com/api/confirm_email/${token}`);
            setMessage(response.data.message);
            setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
        } catch (error) {
            setMessage('Failed to confirm email. Please try again.');
        }
    }, [token, navigate]);

    useEffect(() => {
        confirmEmail();
    }, [confirmEmail]);

    return (
        <div className="email-confirmation">
            <h2>Email Confirmation</h2>
            <p>{message}</p>
        </div>
    );
}

export default EmailConfirmationComponent;
