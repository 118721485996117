// App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import EmailConfirmationComponent from './components/EmailConfirmationComponent';
import CheckEmailComponent from './components/CheckEmailComponent';
import PasswordReset from './components/PasswordReset'; 
import ActualPasswordResetComponent from './components/ActualPasswordResetComponent';
import SetupAssistant from './components/SetupAssistant';
import MainApp from './MainApp';
import TeacherDashboard from './components/TeacherDashboard';
import ProfileSetup from './components/ProfileSetup';
import LoginComponent from './components/LoginComponent';
import DashboardComponent from './components/DashboardComponent';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/reset-password/:token" element={<ActualPasswordResetComponent />} />
        <Route path="/confirm/:token" element={<EmailConfirmationComponent />} />
        <Route path="/check-email" element={<CheckEmailComponent />} />
        <Route path="/dashboard" element={<DashboardComponent />} />
        <Route path="/setup" element={<SetupAssistant />} />
        <Route path="/main" element={<MainApp />} />
        <Route path="/teacher-dashboard" element={<TeacherDashboard />} />
        <Route path="/profile-setup" element={<ProfileSetup />} />
        {/* Add routes for other sub-components here */}
      </Routes>
    </div>
  );
}

export default App;
