import React from 'react';
import './CheckEmailComponent.css';

function CheckEmailComponent() {
  return (
    <div className="check-email">
      <h2>Almost there!</h2>
      <p>Please check your email to confirm your account.</p>
      <p>Once confirmed, you will be redirected to the login page.</p>
    </div>
  );
}

export default CheckEmailComponent;
