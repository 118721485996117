// Footer.js
import React from 'react';
import './Footer.css'; // Make sure to create a corresponding CSS file

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; 2023 Arc Classroom</p>
      {/*<p>Contact Us: info@yourplatform.com</p>*/}
      {/* Add more footer content here */}
    </footer>
  );
}

export default Footer;
