// SignUpForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './SignUpForm.css';

function SignUpForm() {
  const [userData, setUserData] = useState({ username: '', email: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userData.username || !userData.email || !userData.password) {
      setError('Please fill all fields');
      return;
    }
    try {
      const response = await axios.post('https://arcclassroom.com/api/signup', userData);
      console.log(response.data);
      navigate('/check-email'); // Redirect to check email page after successful signup
    } catch (error) {
      setError(error.response?.data?.message || 'Signup failed');
    }
  };

  return (
    <section className="sign-up-form">
      <h2>Join Us</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input type="text" name="username" placeholder="Username" value={userData.username} onChange={handleInputChange} />
        <input type="email" name="email" placeholder="Email" value={userData.email} onChange={handleInputChange} />
        <input type="password" name="password" placeholder="Password" value={userData.password} onChange={handleInputChange} />
        <button type="submit">Sign Up</button>
        </form>
      <div className="password-reset">
        <Link to="/password-reset">Forgot Password?</Link> {/* Add this line */}
      </div>
    </section>
  );
}

export default SignUpForm;
